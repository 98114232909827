import React from 'react'
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../../pages/e-books.module.css'

const LentenMassPage = () => {
  return (
    <Layout>
      <section className={`${styles.jumbo} jumbo h-52 sm:h-72`}>
        <div className="container">
          <h1 className="text-5xl sm:text-8xl text-red-800">Lenten Mass</h1>
        </div>
      </section>
      <section className="content pt-16">
        <div className="container mx-auto px-4 !max-w-[100%]">
          <div className="flex flex-col md:flex-row gap-12">
            <div className="w-full md:w-1/4">
              <StaticImage 
                src="../../images/lenten-mass-cover.jpg"
                alt="Lenten Mass Book Cover"
                className="w-full rounded-lg"
              />
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-4">
                <h2 className="text-3xl font-bold text-gray-900 !mt-0">An Orthodox cookbook for strength and muscle gain during the Great Fast</h2>
                <a 
                  href="https://marciancx.gumroad.com/l/lenten-mass"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-red-800 text-white px-4 py-4 rounded-lg hover:bg-red-900 transition-colors whitespace-nowrap"
                >
                  Get your FREE copy!
                </a>
              </div>
              <div className="space-y-4">
                <p className="text-gray-700 text-base">
                  Fasting doesn't mean weakness. Too many men assume they have to sacrifice strength, muscle, and performance during Lent—but that's not true. With the right recipes, you can train hard, build muscle, and stay strong while honoring the fast.
                </p>
                <p className="text-gray-700 text-base">
                  This guide breaks down:<br />
                  • The history and purpose of the Orthodox fast<br />
                  • The science of muscle retention and protein intake during fasting<br />
                  • 10 high-protein, fast-friendly recipes featuring shrimp, scallops, lentils, and more<br />
                  • Meal timing strategies to maximize energy and recovery
                </p>
                <p className="text-gray-700 text-base mb-8">
                  Fasting is a test of both body and spirit. If you approach it with wisdom, discipline, and the right nutrition, you will emerge from Lent stronger than ever.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LentenMassPage 